<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="'Alterar Categoria do Ticket'"
      :modal="true"
      :closable="false"
    >
      <form
        name="HelpdeskTicketsChangeCategory"
        class="p-col-12"
        @submit.prevent="setCategory"
        style="min-height: 15vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <p v-if="!selected">
          <b>Ticket {{ ticketId }} | {{ ticketSubject }}</b>
        </p>
        <p v-else v-for="ticketSelected in selected" :key="ticketSelected.id">
          <b>Ticket {{ ticketSelected.id }} | {{ ticketSelected.subject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="ticketCategory"
                :options="categoriesList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('ticketCategory') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="ticketCategory">Selecionar Categoria do Ticket</label>
            </span>
            <small
              v-if="errors.has('ticketCategory')"
              class="p-error"
              role="alert"
            >
              Categoria é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setCategory"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ticketService from "../../services/tickets.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "ChangeCategory",
  components: {
    Loading,
  },
  props: [
    "showToast",
    "show",
    "categoryId",
    "ticketId",
    "ticketSubject",
    "selected",
  ],
  watch: {
    categoryId() {
      this.showLoader = false;
      this.$validator.pause();
      this.$validator.reset();
      this.current = !this.selected ? this.categoryId : null;
    },
  },
  data() {
    return {
      current: !this.selected ? this.categoryId : null,
      categoriesList: [],
      showLoader: false,
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      return ticketService
        .getCategoryList()
        .then((response) => (this.categoriesList = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    setCategory() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.selected != null) {
          return this.setCategoryMultipleTickets();
        }
        this.showLoader = true;
        return ticketService
          .setCategory(this.ticketId, { category_id: parseInt(this.current) })
          .then((response) => {
            this.showLoader = false;
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar a categoria ticket",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedTicketCategory", {
                status: "error",
                currenTechnican: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Categoria do ticket alterada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedTicketCategory", {
              status: "ok",
              result: response,
            });
          });
      });
    },
    async setCategoryMultipleTickets() {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      for (const ticket of this.selected) {
        await ticketService
          .setCategory(ticket.id, { category_id: parseInt(this.current) })
          .then((response) => {
            if (!response) {
              return (noErrors = false);
            }
            res = response;
          });
      }
      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar a categoria ticket",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedTicketCategory", {
          status: "error",
          currenTechnican: null,
        });
      }

      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Categoria do ticket alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedTicketCategory", {
        status: "ok",
        result: res,
      });
    },
  },
};
</script>
